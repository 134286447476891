 main {
	 #sidebar-second {
		//background: $sidebar-second-bg;
		margin: $sidebar-second-margin;
		padding: $sidebar-second-padding;
	}

	.side-row {
		background: $side-row-bg;
		border: $side-row-border;
		border-radius: $side-row-border-radius;
		box-shadow: $side-row-box-shadow;
		margin: $side-row-margin;
		padding:$side-row-padding;
		overflow: hidden;

		@include palette-sidebar-slick-controls;
		@include palette-sidebar-button-link;

		 &.section-with-slider {
			 padding:0;
		 }

		.section-inner {
			padding: $side-row-inner-padding;
			@media (max-width:$narrow) {
				padding: $side-row-inner-mobile-padding;
			}		
		}
    .card-title {
      
    }
		h2 {
			background-color:$sapphire_blue;
			color:white;
			font:$side-row-title-font;
			margin: -20px -20px 20px;
      padding: 20px;
    		text-align: center;
			.card-title {
				font-size: inherit;
			}
		}

		.card {
			@include palette-sidebar-cards;

			border-left: $card-sidebar-border;
			border-radius: $card-sidebar-border-radius;
			box-shadow: $card-sidebar-shadow ;
			margin-bottom: $card-sidebar-margin-bottom;

			.card-text {
				padding: $card-text-padding;
			}
		}
		a.card:hover {
			@include card-hover;
		}
		.card-more-link {
			@include palette-sidebar-card-more-link;
		}
		.card-feed-link {
			@include palette-sidebar-card-feed-link;
		}
	}
} 