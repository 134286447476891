.ajax-progress ,ajax-progress-throbber, .flag-throbber {
	display:none;
}
body {
	.view {
		.view-filters {
			opacity:1;
			transition:opacity 0.25s ease-in-out;
		}
		.view-content {
			opacity:1;
			transition:opacity 0.5s ease-in-out;
		}
	}
	&.ajax-processing {
		.view {
			.view-filters {
				opacity:0.5;
			}
			.view-content {
				opacity:0;
			}
			* {
				cursor:wait;
			}
		}
	}
}

.views-exposed-form {
	background-color:$white_smoke;
	padding:20px;
	margin-bottom:20px;
	input[type="reset"],
	input[type="submit"] {
		padding:5px 14px;
	}
	.views-reset-button #edit-reset {
		background-color:$claret;
		&:hover {
			background-color:$utah_crimson;
		}	
	}
	.form-item {
		&.form-item-keywords,
		&.form-type-date-select,
		&.form-item-tag,
		&.form-item-resource-type,
		&.form-item-source {
			border-left: 3px solid $utah_crimson;
		}
	}
	select {
		width:150px;
	}
	
	.container-inline-date > .form-item {
		margin-right:0;
	}
}

@media (min-width: 1400px) {
	select {
		width:180px;
	}
}

@media screen and (max-width: 1200px) {
	.views-exposed-form {
		.views-exposed-widget {
				width: 50%;
				padding: 5px 0 5px 20px;

				@media screen and (max-width: 358px) {
					width: 100%;
				}

			.chosen-container, 
			select {
					max-width: 100% !important;
					width: 100% !important;
			}

			&#edit-keywords-wrapper,
			.form-type-date-select,
			.form-type-date-select #edit-field-date-value-value,
			.form-type-date-select #edit-field-date-value-value .form-item-field-date-value-value-year {
				width: 100%;
			}

			&.views-submit-button,
			&.views-reset-button {
				width: auto;

				.form-submit {
					margin-top: 0.25em;
				}
			}
		}	
	}
}

@media screen and (max-width: 960px) {
	.one-sidebar {
		.views-exposed-widget {
				width: 50%;

			.chosen-container, 
			select {
					max-width: 100% !important;
					width: 100% !important;
			}

			&#edit-keywords-wrapper,
			.form-type-date-select,
			.form-type-date-select #edit-field-date-value-value,
			.form-type-date-select #edit-field-date-value-value .form-item-field-date-value-value-year {
				width: 100%;
			}

			&.views-submit-button,
			&.views-reset-button {
				width: auto;

				.form-submit {
					margin-top: 0.25em;
				}
			}
		}		
	}
}