.front {
	.node-organisation {
		.card-text,
		.card-summary {
			display:none;
		}
	}
}

// MAPS
.map-wrapper {
	.highcharts-container {
		.highcharts-root{
			font-family:$base-font-family;
			font-size:18px;
		}
		// Ocean
		.highcharts-background {
				fill:$anti_flash_white;
		}
		// Empty countries
		.highcharts-series-group .highcharts-map-series {
			.highcharts-null-point {
				fill:white;
				stroke:#C1C4C7;
			}
		}
		// Buttons
		.highcharts-button.highcharts-map-navigation {
			path {
				fill:$utah_crimson;
				stroke:$utah_crimson;
			}
			text {
				stroke:white !important;
				fill:white !important;
			}
			&.highcharts-button-hover {
				path {
					fill:white;
					stroke:white;
				}
				text {
					stroke:$utah_crimson !important;
					fill:$utah_crimson !important;
				}
			}
		}
		// Tooltip label
		.highcharts-tooltip {
			padding:21px;
			border-left: 2px solid red;
			path.highcharts-label-box.highcharts-tooltip-box {
				fill: lighten($pale_grey, 3%);
				stroke:$pale_grey;
				box-shadow:$box-shadow;
			}
		}
		// Tooltip text
		div.highcharts-label.highcharts-tooltip span {
			font-family:$base-font-family !important;
			font-size:18px !important;
		}
	}
}