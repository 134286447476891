// TAGGED CONTENT

.palette-default,
.palette-alt-1,
.palette-alt-2,
.palette-alt-3,
.side-row {
	.card-tagged-view {
		.card-title-et {
			a {
				color:$claret;
				font-size:20px;
				margin-bottom:10px;
				&:hover {
					color:$utah_crimson;
				}
			}
			&:last-child {
				margin-bottom:0;
			}
		}
	}
}

.palette-highlight {
	.card-tagged-view {
		.card-title-et {
		a {
			color:white;
			font-size:20px;
			margin-bottom:10px;
			&:hover {
				color:$moonstone_blue;
			}
		}
			&:last-child {
				margin-bottom:0;
			}
		}
	}
}

// RELATED CONTENT

.view-id-related {
	.view-header {
		.side-row {
			margin: 0 0 -20px;
		}
		h2 {
			margin:-20px -20px 0;
		}
	}
	.view-content {
		padding:20px;
		background-color:$sidebar-second-bg;
		.card-related {
			a {
				color:$claret;
				font-size:20px;
				margin-bottom:10px;
				&:hover {
					color:$utah_crimson;
				}
			}
			&:last-child {
				margin-bottom:0;
			}
		}
	}
}