.view-attachments {
	
	// /* LAYOUT */
	ul {
		padding:0;
		margin:0;
	}
	
	.views-row {
		//padding: $vert-space/2 0;
	}
	
	.views-row-last {
		.attachment-link {
			margin:0;
		}
	}

	.attachment-link {
		display:block;
		overflow: hidden;
		margin-bottom:$vert-space;
		
		.file-icon {
			float:left;
			margin:0 $horz-space 0 0;
			min-width:4rem;
			.file-extension {
				background-color: white;
				padding:$vert-space*0.55 $horz-space*1.1;
				text-transform:uppercase;
				font-size:0.833em;
        		line-height:1;
				text-align:center;
				font-weight:bold;
				width: 90px;
			}
			.file-size {
       			padding: 3px 5px;
				font-size:0.75em;			
				text-align:center;
				color:$dark_slate_gray;
       			//border:$secondary-border;
        		border-top:none;
			}
		}
		.file-name {
			font-size:1.111em;
			overflow:hidden;
			padding: 10px 20px 10px 0;
		}	
	}


	// /* FILE TYPE COLOURS */
  
  .file-type-docx, .file-type-doc {
    .file-extension {
      border:solid 2px $docx_color;
			border-right: solid 4px $docx_color;
      color:$docx_color;
    }
	  .file-size {
		  border-right: solid 4px $docx_color;
	  }
  }
	
  .file-type-xlsx, .file-type-xls {
    .file-extension {
      border:solid 2px $xlsx_color;
			border-right: solid 4px $xlsx_color; 
      color:$xlsx_color;
    }
	  .file-size {
		  border-right: solid 4px $xlsx_color;
	  }
  }
	
  .file-type-pptx, .file-type-ppt {
    .file-extension {
      border:solid 2px $pptx_color;
			border-right: solid 4px $pptx_color;
      color:$pptx_color;
    }
	  .file-size {
		  border-right: solid 4px $pptx_color;
	  }
  }
	
  .file-type-pdf {
    .file-extension {
      border:solid 2px $pdf_color;
			border-right: solid 4px $pdf_color;
      color:$pdf_color;
    }
	  .file-size {
		  border-right: solid 4px $pdf_color;
	  }
  }
	
  .file-type-rtf, .file-type-txt {
    .file-extension {
      border:solid 2px $txt_color;
			border-right: solid 4px $txt_color;
      color:$txt_color;
    }
	  .file-size {
		  border-right: solid 4px $txt_color;
	  }
  }
	
  .file-type-jpg, .file-type-png, .file-type-bmp, gif {
    .file-extension {
      border:solid 2px $jpg_color;
			border-right: solid 4px $jpg_color; 
      color:$jpg_color;
    }
	  .file-size {
		  border-right: solid 4px $jpg_color;
	  }
  }
	
  .file-type-zip, .file-type-rar, .file-type-7z {
	  .file-extension {
			border:solid 2px $zip_color;
			border-right: solid 4px $zip_color;
			color:$zip_color;
	  }
	  .file-size {
		  border-right: solid 4px $zip_color;
	  }
  }
}

//HIGHLIGHT PALETTE
 .palette-highlight .view-attachments {
	.attachment-link {
		.file-icon .file-size {
			color:white;
		}
	}
}

//ALT-1 &ALT-3 PALETTE
.palette-alt-1,
.palette-alt-3 {
	.view-attachments .attachment-link {
		@media (min-width:1600px){
			background: linear-gradient(to right, $white_smoke 90px, white 90px);
		}
		background-color:white;
		.file-icon .file-size {
			background-color:$white_smoke;
		}
	}
}

//ALT-2 PALETTE & SIDEBAR 
.palette-alt-2,
#sidebar-second {
	.view-attachments .attachment-link {
		@media (min-width:1600px){
			background: linear-gradient(to right, $anti_flash_white 90px, white 90px);
		}
		background-color:white;
		.file-icon .file-size {
			background-color:$anti_flash_white;
		}
	}
}

.postscript-first {
	.view-attachments .attachment-link {
		@media (min-width:1600px) {
			width: calc(25% - 10px);
			float: left;
			margin-right: 20px;
		}

		@media (min-width:940px) and (max-width:1599px) {
			width: calc(50% - 20px);
			float: left;
			margin-right: 20px;
		}
	}
}

main {
	.view-attachments .attachment-link {
		@media (min-width:1600px) {
			width: calc(50% - 20px);
			float: left;
			margin-right: 20px;
		}
		@media (max-width:1599px) {
			width: 100%;
			float: none;
			margin-right: 0;
		}
	}
}
main #sidebar-second {
	.view-attachments .attachment-link {
		background: linear-gradient(to right, $anti_flash_white 90px, white 90px);
		width: 100%;
		float: none;
		margin-right: 0;
	}
}