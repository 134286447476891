.highcharts-root * {
  font-family: $highcharts-font-family !important;
}

.map-world-wrapper {
  margin: $highcharts-container-margin;
}

.map-wrapper {
  height:260px;
  @media(min-width:540px) {
    height:360px;
  }
  @media(min-width:960px) {
    height:540px;
  }
  @media(min-width:1200px) {
    height:720px;
  }
}