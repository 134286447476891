/* FAQ COLLAPSE/EXPAND STYLES */
.view-faqs {
	margin-bottom: 20px;

	.card-qa-answer {
		display: none;
		padding: 10px;	
		box-sizing: border-box;
 
		:last-child {
			margin-bottom: 0;
		}
	}
	
	.card-qa-question {
		text-decoration: none;
		color: $primary;
		padding: 10px 0 10px 30px;
		line-height: 20px;
		display: block;
		/*border: 1px solid #d0d0d0;*/
		margin-bottom: -1px;
		cursor: pointer;
		box-sizing: border-box;
		background: $ico-expand no-repeat scroll 0 50% / 16px 16px transparent;
		&:hover {
			background: $ico-expand-claret no-repeat scroll 0 50% / 16px 16px transparent;
		}

		&.active {
			font-weight: bold;
			background: $ico-collapse no-repeat scroll 0 50% / 16px 16px transparent;
			&:hover {
				background: $ico-collapse-claret no-repeat scroll 0 50% / 16px 16px transparent;
			}
		}
	}
}

/* HIGHLIGHT PALETTE */
.palette-highlight .view-faqs {
	.card-qa-question {
		background: $ico-expand-white no-repeat scroll 0 50% / 16px 16px transparent;
		color:white;
		text-decoration: underline;
		&:hover {
			background: $ico-expand-blue no-repeat scroll 0 50% / 16px 16px transparent;
			color:$moonstone_blue;
		}

		&.active {
			background: $ico-collapse-white no-repeat scroll 0 50% / 16px 16px transparent;
			color:white;
			text-decoration: underline;
			&:hover {
				background: $ico-collapse-blue no-repeat scroll 0 50% / 16px 16px transparent;
				color:$moonstone_blue;
			}
		}
	}
}
