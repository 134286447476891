// /* DEFAULT CARD LAYOUT	*/
.card {
	border-left: $card-border;
	border-radius: $card-border-radius;
	//box-shadow:$card-shadow;
	margin-bottom:$card-margin-bottom;
	
	transition: $card-transition;
	&.slick-slide {
		margin-top: 10px;
	}
	.card-title,
	.card-title-et,
	.card-title-field {
		font-family:$card-title-font-family;
	}

	h3 {
		display:block;
		font:$card-title-font;
		
		.card-title-field,
		.card-title-et,
		.card-title {
			display:block;
			font:$card-title-font;
		}
	}	
	.card-text {
		padding: $card-text-padding;
		a { word-wrap: break-word; }
	}
}

.card-display-date {
	background: $card-date-bg-color;
	border-bottom:$card-date-border;
	border-radius:$card-date-border-radius;
	color:$card-date-text-color;
	font:$card-date-font;
	padding:12px 20px 10px;
	margin-bottom:-10px;
	text-transform:uppercase;
}


// /* MORE LINK */
.section-row:not(.view-type-slider) //, .side-row:not(.view-type-slider) /* to target sidebar more links separately */ 
	{
	.card-more-link {
		display:table !important;
		margin-left:2.35765%;
		text-align:center;

		@media (min-width:0) and (max-width:540px) {
			margin-left:0;
			width:100%;
		}

		a.card {
			box-sizing:border-box;
			display:table-cell;
			font:$card-more-link-font;
			padding:$vert-space $horz-space;
			vertical-align:middle;
			width:100%;

			@media (min-width:0) and (max-width:719px) {
				display: block;
			}

			&:after {
				content:" ";
				display:inline-block;
				height:20px;
				margin:0 0 -2px 10px;
				width:14px;
			}
		}
	}

	&.row-max-1 {
		.card-more-link,
		.card-last-link-url {
			margin-left: 0;
			float: right;
		}
	}
}

// /* FEED LINK */
.card-feed-link {
	clear: both;
	display: block;
	float: right;
	font:$card-feed-link-font;
	overflow: hidden;
	text-transform: uppercase;
	a {
		&:after {
			content: "";
			display: inline-block;
			width: 28px;
			height: 26px;
			//border-radius: 50%;
			margin-left: 10px;
			vertical-align: sub;
		}
	}
}



// /* INNER CARD ELEMENTS */
.tweet .card-text {
	display:block;
	font:normal 1.125em/1.22222222222222 $base-font-family;
}

.card-logo {
	background:#FFFFFF;
}
.card-video {
	margin-bottom:15px;
	
	span.ext {
		display:none;
	}
}

.card-tags {
	display: inline-block;
	font-size: 0.875em;
	font-weight: 600;

	ul {
		display: inline-block;
		li {
			float: left;
			margin-right: 10px;
			&:after {
				content:"|";
				display: inline-block;
				float: right;
				margin-left: 10px;
			}
			&:last-child {
				&:after {
					content:"";
				}
			}					
		}
	}
}

.card-start-date {
	margin-right:2px;
	padding:$vert-space*0.7 $horz-space*0.7 $vert-space*0.2;
	text-align:center;
	
	.card-date-day {
		font-size:1.75em;
		line-height:0.78571428571429;
	}
	.card-date-month {
		font-size:0.875em;
		line-height:1.22222222222222;
		text-transform:uppercase;
	}
}

.card-citation {
	font-style:italic;
}
.card-abbr-authors {
	font-size:0.875em;
	margin-top:10px;
}
.card-type {
	font-size:0.875rem;
	line-height:1.57142857142857;
	display: none;
}
.card-label {
	font-weight:bold;
}
.card-start-date {
	margin-right:2px;
	padding:$vert-space*0.7 $horz-space*0.7 $vert-space*0.2;
	text-align:center;
	
	.card-date-day {
		font-size:1.75em;
		line-height:0.78571428571429;
	}
	.card-date-month {
		font-size:0.875em;
		line-height:1.22222222222222;
		text-transform:uppercase;
	}
}

.card-date-display-override {
	font-size: 0.875em;
	text-transform:uppercase;
	margin-bottom:10px;
}
.card-resource-type {
	display:inline-block;
	font-weight: 400;
	font-size: 0.875em;
	margin-top:10px;
}
.card-content-type {
	display:none;
}
.card-journal {
	display: inline-block;
	font-weight: 600;
	font-size: 0.875em;
	margin-top:10px;
	/*&:after {
		content:"|";
		margin: 0 0px 0 2px;	
	}*/
}
.card-email {
	font-weight: 600;
}

.card-role {
	margin-top:10px;
}

.card-job-title {
	font-size:1em;
	line-height:1.375;
}

.card-footer {
	display: block;
	line-height: 1.125em;
}

@media (min-width:$narrow) and (max-width:959px) {
	.card-with-summary {
		.card-summary {
			font-size: 0.875rem;
			line-height: 1.42857142857143;
		}
	}
}
//Display box-link card-summary on hover 
//@include card-hidden-summary;

// EVENTS CARDS

.date-box {
	padding:0 10px 2px;
	text-transform:uppercase;
	display: inline-block;
	float:left;
	height:55px;
	width:58px;
	text-align:center;
	.event-day {
		font:$event-box-day;
		margin-top:-2px;
	}
	.event-month {
		font-size: 0.875em;
	}
}
.palette-default,
.palette-alt-1,
.palette-alt-2,
.palette-alt-3,
.side-row {
	.date-box {
		background-color:$utah_crimson;
		color:white;
	}
}
.palette-highlight {
	.date-box {
		background-color:white;
		color:$utah_crimson;
	}
}

.poscript-first .section-row {
	.palette-default,
	.palette-alt-1,
	.palette-alt-2,
	.palette-alt-3,
	.side-row,
	.palette-highlight {
		.node-organisation .card {
			border-left:4px solid blue; 
		}
	}
}

.postscript-first .section-row.palette-alt-3 a.card .card-display-date {
	color:white;
}

.poscript_first .view-sections .section-row {
	&.palette-default,
	&.palette-alt-1,
	&.palette-alt-2,
	&.side-row,
	&.palette-highlight {
		.card {
			.card-date,
			.card-display-date,
			.card-location,
			.date-display-single {
				color:$dark_slate_gray;
			}
		}
	}
}
.card-date,
.card-location {
	font-size:0.875em;
	line-height:1.57142857142857;
	text-transform:uppercase;
	margin-top:10px;
}

.row-max-2 .view-organisations {
	@media (min-width:1200px) {
		margin:0 30%;
		width:40%;
	}
	
	@media (min-width:720px) and (max-width:1199px) {
		margin:0 20%;
		width:60%;
	}
}