// GENERAL / RESET STYLES

//.front .page > main { display:none; }

.section-row.section-with-slider { padding:0; }

.section-row.section-with-slider .section-inner {
	margin: 0 auto;
	max-width:$site-max-width;
	padding:0;
	position: relative;
}

.slick-slide { margin-left:10px; margin-right:10px; }

// /* COMMON CONTROL/NAV STYLES */
.slick-controls, .slick-nav {
	button.slick-arrow {
		bottom:17px;
		z-index:1000;
		text-indent:-999em;
		font-family:$base-font-family;
		overflow:hidden;
		padding: 20px;
		&:before {
			content:"";
		}
		&.slick-prev {
			left:1.25rem;
			background-size:contain;
			@include media($narrow) {
				left:0;
			}
		}
		&.slick-next {
			right:1.25rem;
			background-size:contain;
			@include media($narrow) {
				right:0;
			}
		}
	}
	.slick-dots {
		padding:0 20px;
		li {
			width:10px;
			height:10px;
			margin:5px;
			button {
				display:inline-block;
				width:6px;
				height:6px;
				border-radius:50%;
				padding:4px;
				&:before {
					content:"";
					display:none;
				}
				//&:hover {
//					background-color:$utah_crimson;
//				}
			}
		}
	}	
}

// /* SLICK CONTROLS ONLY -- everywhere but slides view which uses .slick-nav class instead
.slick-controls {
	height:56px;
	position:relative;
	
	@include media($narrow) {
		display:inline-block;
	}

	button.slick-arrow {
		bottom:17px;
		padding: 20px;
	}
	
	.slick-dots {
		bottom:17px;
	}	
}

.section-row {
	.slick-controls {
		@include media($narrow) {
			width:50%;
		}
		@include media($normal) {
			width:33.333%;
		}
	}
}
.one-sidebar {
	.content-main,
	.content-additional {
		.section-row {
			.slick-controls {
				@include media($narrow) {
					width:100%;
				}
				@include media($normal) {
					width:50%;
				}
			}
		}
	}
}

.side-row {
	.slick-controls {
		width: 100%;
		
		.slick-dots {
			@include media($narrow) {
				display: none !important;
			}
			@include media($wide) {
				display: block !important;
			}
		}
	}
	
	.view-twitter .slick-controls .slick-dots {
		@include media($narrow) {
			display: none !important;
		}
		@include media(1450px) {
			display: block !important;
		}
	}
}



// /* SLICK NAV ONLY
.slick-nav {
	height: 50px;
	&:empty { display: none; }
	button.slick-arrow {
		padding: 25px;
		@media (min-width:740px) and (max-width:1200px) {
			padding:15px;
		}
	}
	.slick-dots {
		bottom:10px;
	}	
}






// SLIDER VIEWS
.view-slider {
	position:relative;
	.view-content {
		margin:0;
		.slick-list {
			overflow:visible;
			.slick-track {
				background-color:black;
				
				@include media($narrow) {
					background-color: transparent;
				}
				
				.views-row {
					margin: 0;

					.card-slide-image {
						position:relative;
						img {
							max-width:none;
							position:relative;
							width: 100%;

							@include media($narrow) {
								left: -10px;
								width: calc(100% + 20px);
							}

							@include media($wide) {
								left:calc(50% - 800px);
								width: auto;
							}
						}
					}
					.card-slide-description {
						bottom:0;
						position:relative;
						background-color:$slide-text-bg-color;
						font:$slide-text-font;
						padding:17px 20px 55px 20px;
						margin:0;
						color:$slide-text-color;
						min-height:66px;
						border-left:8px solid $utah_crimson;
					
						p {
							font:$slide-text-font;
							margin: 0;
						}
						
						@media (max-width:719px) {
							padding:17px 20px 115px 20px;
							margin:0 0 -55px;
						}

						@include media($narrow) {
							background-color:$slide-text-bg-color;
							position:absolute;
							padding:16px 60px 90px 60px;
							margin-left:120px;
							width:51%;
						}
						
						@media (min-width:740px) and (max-width:1199px) {
							//font-size:1.2rem;
							width:60%;
							padding:10px 30px 60px 30px;
						
							p {
								font-size:1.2em;
							}
						}
						@media (min-width:1200px) {
							//font-size:2rem;
						
							p {
								font-size:2em;
							}
						}
					}
				}
			}
		}
		
		.card-edit-node a {
			@include button-states($button-bg, $button-text, $button-border-color);
			font-size: 0.875rem;
			font-weight: bold;
			padding: 5px 15px;
			position: absolute;
			left: 0;
			text-transform: uppercase;
			top: 0;
		}
	}
	
	// SLIDER NAVIGATION
	.view-footer {
		position:absolute;
		bottom:0;
		@include media($narrow) {
			bottom:20px;
		}
		@media (min-width:740px) and (max-width:1200px) {
			bottom:0;
			left:31%;
		}
		width:240px;
		left:26%;
	}
}

.section-row .view-slider .view-content .slick-list .slick-track .views-row {
	@include media($narrow) {
		margin-left: 10px;
		margin-right: 10px;
	}
}


// SLIDERS HINDERED BY SIDEBARS - image
.one-sidebar,
.sidebar-second {
	.content-main,
	.content-additional,
	.sidebar {
		.view-slider {
			.view-content .slick-list .slick-track .views-row {
				.card-slide-image img {
					left: auto;
					width: 100%;
					max-width: 100%;
				}
				.card-slide-description {
					bottom:0;
					position: relative;
					width:100%;
					margin-left:0;
					padding:20px;
					font-size:1.11em;
					background-color:$anti_flash_white;
					color:$raisin_black;
					border-left:none;
				}
			}
			.view-footer {
				bottom: 0;
				position:relative;
				width:50%;
				left: auto;
				margin: 0 25%;
			}
		}
	}
}


// PHOTO GALLERY
.card-photos {
	position:relative;
	.photoswipe-gallery {
		margin:0;
		.slick-slider {
			margin-bottom:0;
			.slick-list {
				.views-row {
					.card-slide-image {
						position:relative;
						height:450px;
						img {
							max-width:none;
							position:absolute;
							left:50%;
							margin-left:-800px;
						}
					}
					.card-slide-description {
						width:100%;
						bottom:0;
						position:absolute;
						background-color:rgba(35,31,32,0.8);
						padding:17px 20px 51px 20px;
						@include media($narrow) {
							padding:17px 340px 17px 20px;
						}
						font-size:1.125rem;
						color:white;
						line-height:1.225;
					}
				}
			}
		}
	}
	
	// GALLERY CONTROLS
	.view-footer {
		text-align:center;
	}
}

// SLIDER / TWITTER
.view-type-slider, .view-twitter {
	position:relative;
	.view-content {
		margin:0;
		position: relative;
		.slick-slider {
			margin-bottom:0;
			.slick-list {
				.views-row {
					.card-slide-image {
						position:relative;
						height:450px;
						img {
							max-width:none;
							position:absolute;
							left:50%;
							margin-left:-800px;
						}
					}
					.card-slide-description {
						width:100%;
						bottom:0;
						position:absolute;
						background-color:rgba(35,31,32,0.8);
						padding:17px 20px 51px 20px;
						@include media($narrow) {
							padding:17px 340px 17px 20px;
						}
						font-size:1.125rem;
						color:white;
						line-height:1.225;
					}
					.tweet {
						.card-date {
							margin-top:10px;
							text-transform: uppercase;
						}
					}
				}
			}
		}
		
	}
	.view-footer {
		text-align:center;
	}
}

// TWITTER SPECIFIC ALT-3
.postscript-first,
.hero {
	.section-row.palette-alt-3 .view-twitter .view-content {
		.card-text {
			a {
				text-decoration:underline;
				color:white;
				&:hover {
					color:rgba(250,250,250,0.8);
				}
			}
		}
	}
}