//@import url(https://fonts.googleapis.com/css?family=Oswald:400,700|Roboto:400,400i,700,700i);
@import url("https://use.typekit.net/mfq1fch.css");


$base-font-family      		: 'open-sans','Helvetica', sans-serif;
$title-font-family    		: 'ff-meta-web-pro','Georgia', serif;
$subtitle-font-family 	 	: 'ff-meta-web-pro','Georgia',serif;


$base-font-size						: 18px;
$base-line-height					: 1.5;

$base-font-size-normal		: 18px;

$header-menu-font 				: /*0.875rem*/ 0.778rem/1 $base-font-family;
$header-search-field-font : 0.875rem/1.286 $base-font-family;

$nav-base-font        	 	: 1.25rem/1.3 $title-font-family;
$nav-base-font-smaller 		: 0.88888888888889em/1.5 $title-font-family;
$nav-sub-base-font        : 1rem/1.3 $base-font-family;

$breadcrumb-font 					: /*1rem*/ 0.889rem/1 $title-font-family;

$section-row-title-font 	: 500 1.778rem/1.25 $title-font-family; // /* Nugget title */
$section-row-title-font-smaller 	: 500 2rem/1.25 $title-font-family; // /* Nugget title */ 

$side-row-title-font 			: 1.25rem/1.3 $title-font-family; // /* Sidebar nugget title */

$card-title-font-family		: $base-font-family;
$card-title-font        	: normal 1.125rem/1.22222222222222 $card-title-font-family;
$card-font-family       	: $base-font-family;
$card-more-link-font			: normal 1.125rem/1 $base-font-family;
$card-feed-link-font			: 600 1em/1.46153846153846 $base-font-family;

$button-link-font 				: 600 $base-font-size/1 $title-font-family;

/* FORMS */
$button-font 							: 500 $base-font-size/1 $title-font-family;

$event-box-day				: 800 1.777rem/1em $title-font-family;
$form-label-font			: 500 1.111rem/1em $title-font-family;