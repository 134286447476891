#site-footer {
	.outer-wrapper {
		padding:0 $horz-space/2;
	}

	h2 {
		color:$footer-first-text;
		text-align: left;
		font-weight: 500;
		&:before {
			content:"|";
			color:$pink_sherbet;
    		font-size: 22px;
    		display: inline-block;
    		vertical-align: bottom;
    		margin-bottom: 6px;
		}
		.card-title {
			display: inline-block;
		}
	}
	
	ul:not(.contextual-links) {
		padding: 0;

		li {
			list-style: none;
			line-height: 1.5em;
		}
	}
	
	.card-follow-us-on ul li { display: inline-block; }

	.footer-first {
		background: $footer-first-bg;
		color: $footer-first-text;
		padding:$vert-space 0;
		@include palette-footer-first-slick-controls;
		
		.outer-wrapper {
			border-bottom:$footer-first-border;
			padding-bottom:$vert-space/2;
		}
		
		.card {
			@include palette-footer-first-cards;
			border-left:none;
			float: left;
    		width: calc(50% - 20px);
    		margin-right: 20px;
		}
		a.card:hover {
			@include card-hover;
			border-left:none;
		}
		.card-more-link {
			@include palette-footer-first-card-more-link;
		}		
		.card-feed-link {
			@include palette-footer-first-card-feed-link;
		}		

		
		a {
			color:$footer-first-action;
			&:hover {
				color:$footer-first-action-hover;
			}
		}
	}
	.footer-second {
		background: $footer-second-bg;
		color: $footer-second-text;
		padding:$vert-space 0;
		@include palette-footer-second-slick-controls;
		
		a {
			color:$footer-second-action;
			&:hover {
				color:$footer-second-action-hover;
			}
		}
		.card {
			@include palette-footer-second-cards;
		}
		a.card:hover {
			@include card-hover;
		}
		.card-more-link {
			@include palette-footer-second-card-more-link;
		}		
		.card-feed-link {
			@include palette-footer-second-card-feed-link;
		}		
		
		section.block {
			margin-bottom:$vert-space;
			@media screen and (min-width: 45em) {
				margin-bottom:0;				
			}
			&:last-child {
				margin-bottom:0;
			}
		}
		.footer-block {
			margin-bottom:$vert-space*2;
			
			@media screen and (min-width: 45em) {
				margin-bottom:0;				
			}
		}
		
	}
	.footer-third {
		background: $footer-third-bg;
		color: $footer-third-text;
		padding:$vert-space/2 0;
		text-align:center;
		font-size:0.875em;
		font-weight:600;
		
		a {
			color:$footer-third-text;
			&:hover {
				color:$footer-third-action-hover;
			}
		}
		#manta-ray-media {
			float: none;
			margin:10px 0;
			@include media(880px) {
				float: right;
			}
			a {
				color: $sapphire_blue;
				&:hover {
					color: $utah_crimson;
				}
			}
		}
		.block-menu {
			float: none;
			@include media(880px) {
				float: left;
			}
			ul {
				margin:10px 0;
				li {
					display: inline-block;
					margin-right: 5px;
					padding-right: 10px;
					border-right: 1px solid $secondary-border-color;
				}
				li.last {
					border-right: none;
					margin-right: 0;
					padding-right: 0;
				}
			}
		}
	}

	.card-links {
		display: inline-block;
		margin-right: 10px;
		text-indent: -9999px;
		
		a {
			background-color: $card-links-action-bg;
			border-radius: $card-links-border-radius;
			width: $card-links-width;
			height: $card-links-height;
			display:block;
			margin:0;
			
			&:hover {
				opacity: $card-links-hover-opacity;
				border-radius: $card-links-hover-border-radius;
				background-color: $card-links-hover-bg;
			}
		}
		
		/* newsletter */
		.newsletter a,
		a[href^="http://eepurl.com"] {
			background: $ico-newsletter no-repeat scroll 50% 50% / 19px 18px $utah_crimson;
			&:hover {
				background: $ico-newsletter-over no-repeat scroll 50% 50% / 19px 18px $claret;
			}
		}

		/* linkedin */
		.linkedin a,
		a[href*=".linkedin.com"] {
			background: $ico-linkedin-white no-repeat scroll 50% 50% / 19px 18px $utah_crimson;
			&:hover {
				background: $ico-linkedin-white no-repeat scroll 50% 50% / 19px 18px $claret;
			}
		}
		
		/* twitter */
		.twitter a,
		a[href*="twitter.com"] {
			background: $ico-twitter-white no-repeat scroll 50% 50% / 20px 20px $utah_crimson;
			&:hover {
				background: $ico-twitter-white no-repeat scroll 50% 50% / 19px 18px $claret;
			}
		}

		/* facebook */
		.facebook a,
		a[href*="facebook.com"] {
			background: $ico-facebook-white no-repeat scroll 50% 50% / 18px 18px $utah_crimson;
			&:hover {
				background: $ico-facebook-white no-repeat scroll 50% 50% / 19px 18px $claret;
			}
		}

		/* google+ */
		.g-plus a,
		a[href*=".google.com"] {
			background:$ico-gplus-white no-repeat scroll 50% 50% / 19px 18px $utah_crimson;
			&:hover {
				background: $ico-gplus-white no-repeat scroll 50% 50% / 19px 18px $claret;
			}
		}
		
		/* youtube */
		.youtube a,
		a[href*=".youtube.com"] {
			background:$ico-youtube-white no-repeat scroll 50% 50% / 19px 18px $utah_crimson;
			&:hover {
				background: $ico-youtube-white no-repeat scroll 50% 50% / 19px 18px $claret;
			}
		}
		
		/* flickr */
		.flickr a,
		a[href*=".flickr.com"] {
			background:$ico-flickr-white no-repeat scroll 50% 50% / 19px 18px $utah_crimson;
			&:hover {
				background: $ico-flickr-white no-repeat scroll 50% 50% / 19px 18px $claret;
			}
		}
	}


	.copyright {
		padding-left: 1em;
	}

	.utility-menu {
		ul {
			text-align: right;
			font-weight: 600;

			li {
				display: inline;
				padding-right: 1em;

				a {
					color: $footer-first-action;
				}
			}
		}
	}
}
