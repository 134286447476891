#sidebar-second {
	.view-header h2 {
		background-color:$sapphire_blue;
		color: white;
		padding: 20px;
		text-align: center;
	}
	
	.view-news-events .view-header h2 {
		background-color:$anti_flash_white;
		color: $raisin_black;
		padding-top: 15px;
		padding-bottom: 0px;
	}
}

// RESOURCES

.node-type-resource .view-display-id-details {
	background-color:$sidebar-second-bg;
	margin-bottom:20px;
	margin-top:-20px;
	padding:20px;
	.view-header { 
		background-color:$sapphire_blue;
		color: white;
		margin:-20px -20px -10px -20px;
		text-align: center;
	}
	.card-label {
		margin-top:10px;
	}
	.card-resource-type {
		font-size:1em;
	}
}

// PUBLICATIONS
.node-type-publication {
	#sidebar-second {
		.block h2 {
			background-color:$sapphire_blue;
			color: white;
			padding: 20px;
			text-align: center;
			font-size:24px;
			font-weight: 500;
		}
		
		.view-display-id-details {
			background-color:$sidebar-second-bg;
			margin-bottom:20px;
			margin-top:-20px;
			padding:20px;
			
			.card-journal {
				font-size: 1rem;
				margin:0;
			}
			
//			.card-link {
//				margin-bottom: 10px;
//			  	a {
//					word-break: break-all;
//			  	}
//				.card-label {
//					display:none;
//				}
//			}
//			.card-doi .card-label {
//				display:none;
//			}
//			.card-journal {
//				float: left;
//				margin-top:0;
//			  	div {
//					float: left;
//			  	}
//				&:after {
//					content:"|";
//					margin: 0 3px;	
//				}
//			}
//			.card-citation-date,
//			.card-volume,
//			.card-pages {
//				display: inline-block;
//				font-size: 0.875rem;
//				font-weight: 400;
//				vertical-align: super;
//			}
//			.card-volume::before {
//				content: "Vol. ";
//			  	display: inline-block;
//			  	float: left;
//			}
//			.card-volume::after {
//				content: ": ";
//			  	display: inline-block;
//			}
//			.card-volume div {
//			  	display: inline-block;
//			}
		}
	}
}